/* eslint-disable no-underscore-dangle */

// eslint-disable-next-line import/no-extraneous-dependencies
import { Carousel } from 'bootstrap'
import { Swipe } from '../helpers/swipe'

document.querySelectorAll('.carousel').forEach(carousel => {
  Swipe.useForCarousel(Carousel.getOrCreateInstance(carousel))

  const carouselIndicators = () =>
    document.querySelectorAll(
      `.carousel-indicators button[data-bs-target='#${carousel.id}']`
    )

  const currentCarouselIndicatorCount = () =>
    document.querySelector(
      `.carousel-indicator-count[data-bs-target='#${carousel.id}'] .current`
    )

  carousel.addEventListener('slide.bs.carousel', event => {
    carouselIndicators().forEach((button, index) => {
      if (index <= event.to) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    })

    const indicatorCount = currentCarouselIndicatorCount()
    if (indicatorCount) {
      indicatorCount.innerHTML = `${event.to + 1}&nbsp;`
    }
  })
})

const parseStyleToNumber = style => parseInt(style.replace('px', ''), 10)

const CONTENT_GUTTER_X = 16

document.querySelectorAll('.carousel.show-neighbours').forEach(carousel => {
  const carouselItemCount = () =>
    carousel.querySelectorAll('.carousel-item').length

  const carouselPushSize = to => {
    if (to === 0) {
      return 0
    }

    const columnGapStyle = getComputedStyle(
      carousel.querySelector('.carousel-inner').firstElementChild
    ).getPropertyValue('column-gap')

    const gapSize = columnGapStyle ? parseStyleToNumber(columnGapStyle) : 0
    const carouselItemSize = carousel.querySelector('.carousel-item')
      .clientWidth
    const screenSize = window.innerWidth

    if (to === carouselItemCount() - 1) {
      return (
        carousel.querySelector('.carousel-inner').scrollWidth -
        screenSize +
        CONTENT_GUTTER_X * 2
      )
    }

    const occupiedSpace = carouselItemSize + gapSize
    const remainingSpace = screenSize - occupiedSpace - CONTENT_GUTTER_X / 2

    return occupiedSpace * to - remainingSpace / 2
  }

  carousel.addEventListener('slide.bs.carousel', event => {
    const carouselInner = carousel.querySelector('.carousel-inner')
    carouselInner.style.transform = `translateX(-${carouselPushSize(
      event.to
    )}px)`
  })
})
